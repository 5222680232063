<template>
  <CommonHeader />
  <div class="album album-error">
    <HeaderBanner />
    {{ this.customConfig.ERROR_CONTENT }} :
    <code>{{ this.$route.params.error }}</code>
  </div>
  <CommonFooter />
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import HeaderBanner from "@/components/HeaderBanner.vue";
import { headTags } from "@/scripts/headTags";

export default {
  name: "ErrorView",
  components: {
    CommonHeader,
    CommonFooter,
    HeaderBanner,
  },
  data: function () {
    return {
      title: this.customConfig.PAGE_TITLES.ERROR,
      error: this.$route.params.error
    };
  },
  methods: {
    setUpHead() {
      let head = {
        title: this.customConfig.PAGE_TITLES.HOME + " : " + this.title,
        description: this.customConfig.PAGE_DESCS.ERROR,
        type: "website",
        image:
          window.location.origin + require("@/assets/images/main-banner.png")
      };
      headTags(head);
    }
  },
  created: function () {},
  mounted: function () {
    this.setUpHead();
  }
};
</script>
