<template>
  <form>
    <div class="mb-3 row">
      <div class="col-12 col-md-12">
        <label for="search" class="form-label visually-hidden"
          >Formularz wyszukiwania</label
        >
        <input
          type="text"
          class="form-control"
          id="search"
          v-model="inputQuery"
          placeholder="Wpisz frazę wyszukiwania"
        />
      </div>
      <div
        class="col-12 col-md-12 search-results-container"
        :class="{ active: active }"
      >
        <h4 v-if="!posts"></h4>
        <h4 v-else>Wyników: {{ count }}</h4>
        <ul class="search-results" :key="key">
          <li v-for="(post, index) in posts" :key="index">
            <router-link :to="'/wpis/' + post.slug" @click="allowOverflow()">{{
              post.title
            }}</router-link>
            <span v-html="' ' + post.date"></span>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>

<script>
import httpClient from "@/scripts/httpClient";

export default {
  name: "SearchModule",
  data: function () {
    return {
      inputQuery: null,
      posts: null,
      query: null,
      count: null,
      key: true,
      active: false,
    };
  },
  methods: {
    allowOverflow() {
      // prevent overflow blocking from FilterWidget
      document.querySelector("body").style.overflow = "auto";
    },
    builSearchdQuery() {
      if (this.inputQuery.length > 2) this.sendReq();
    },
    sendReq() {
      httpClient(
        this.customConfig.API_PATH +
          "/search?search=" +
          this.inputQuery +
          "&per_page=100"
      ).then((resp) => {
        let _posts = [];

        _posts = resp.data.filter((e) => {
          return e.subtype == "post";
        });

        this.count = _posts.length;

        let _posts_data = [];

        if (this.count > 0) {
          this.active = true;
          _posts.forEach((p) => {
            httpClient(this.customConfig.API_PATH + "/posts/" + p.id).then(
              (resp) => {
                _posts_data.push({
                  id: resp.data.id,
                  title: resp.data.title.rendered,
                  date: resp.data.date.substring(0, 10),
                  slug: resp.data.slug,
                });
                if (this.count == _posts_data.length) this.posts = _posts_data;
              }
            );
          });
        }
      });
    },
  },
  watch: {
    inputQuery() {
      this.posts = null;
      this.active = false;
      if (this.inputQuery.length > 2) this.sendReq();
    },
  },
};
</script>
