<template>
  <div class="container container-header">
    <header class="pb-3 mb-4 border-bottom row">
      <div class="col-10 col-md-10">
        <a
          href="/"
          class="d-flex align-items-center text-dark text-decoration-none header-logos"
        >
          <img src="@/assets/images/uw.png" class="logo" />
          <img src="@/assets/images/uw_contrast.png" class="logo" />

          <div>
            <img src="@/assets/images/banner4.png" class="logo-banner" />
            <img
              src="@/assets/images/banner4_contrast.png"
              class="logo-banner"
            />
          </div>
        </a>
      </div>
      <div class="col-2 col-md-2 wcag-tools">
        <div class="font-resize" @click="fontResize()">
          <span>A</span><span>A</span><span>A</span>
        </div>
        <div class="toggle-dark-mode" @click="toggleDarkMode()">
          <img src="@/assets/images/contrast.svg" v-if="!showdm" />
          <img src="@/assets/images/contrastdm.svg" v-else />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "CommonHeader",
  data: function () {
    return {
      dm: false,
      key: true,
      showdm: null,
      fi: 1,
    };
  },
  methods: {
    fontResize() {
      let body = document.querySelector("body");
      let fontsize = ["small", "standard", "big"];
      if (this.fi == 1) {
        this.fi++;
      } else if (this.fi == 2) {
        this.fi = 0;
      } else if (this.fi == 0) {
        this.fi = 1;
      }
      body.removeAttribute("font-size");
      body.setAttribute("font-size", fontsize[this.fi]);
    },
    toggleDarkMode() {
      this.dm = !this.dm;
      //localStorage.setItem("dark-mode", Boolean(this.dm));
      document.querySelector("body").classList.toggle("dark-mode");
      if (document.querySelector("body").classList.contains("dark-mode")) {
        this.showdm = true;
      } else {
        this.showdm = false;
      }
      if (typeof Storage !== "undefined") {
        localStorage.setItem("dark-mode", this.showdm);
      }
    },
    checkDarkMode() {
      console.log("sprawdzam dm");
      if (typeof Storage !== "undefined") {
        if (typeof localStorage.getItem("dark-mode") !== "undefined") {
          this.showdm = localStorage.getItem("dark-mode");
          console.log(this.showdm);
          if (this.showdm == true) {
            document.querySelector("body").classList.add("dark-mode");
          } else {
            document.querySelector("body").classList.remove("dark-mode");
          }
          //localStorage.setItem("dark-mode", this.dm);
        }
      }
    },
    setHead() {
      let main = document.querySelector("main");
      main.removeAttribute("class");
      main.classList.add(this.$route.name);
    },
  },
  mounted: function () {
    //this.checkDarkMode();
    this.setHead();
    if (document.querySelector("body").classList.contains("dark-mode")) {
      this.showdm = true;
    } else {
      this.showdm = false;
    }
  },
  watch: {
    $route() {
      this.setHead();
    },
  },
};
</script>
