<template>
  <div
    class="simple-widget"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ active: hover }"
  >
    <div class="hello-text"><span>ANKIETA</span></div>
    <div class="description-text">
      <div>
        Tworzymy Bazę Projektów z zakresu humanistyki cyfrowej na UW.
        <br /><a
          href=" https://baza.humanistykacyfrowa.uw.edu.pl/"
          target="_blank"
          >Dodaj swój projekt wypełniając ankietę
          <img src="@/assets/images/arrow.png" class=""
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleWidget",
  data() {
    return {
      hover: false,
    };
  },
  mounted: function () {
    //this.checkLs();
  },
  methods: {},
};
</script>
<style scoped>
/* simple widget */

.simple-widget {
  position: fixed;
  top: 50vh;
  right: 0rem;
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  height: 10rem;
  background: transparent;
  color: #f9f7f1;
  cursor: pointer;
}

.simple-widget.active {
  width: auto;
}

@media (max-width: 768px) {
  .simple-widget {
    right: 0rem;
  }
}

.simple-widget .hello-text {
  background: #73262d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
}

.simple-widget .hello-text span {
  background: #73262d;
  display: block;
  position: relative;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  padding: 2rem;
  text-align: center;
}

.simple-widget .description-text {
  width: 0%;
  background: #73262d;
  background: transparent;
  -webkit-transition: width 1s ease-in-out, background-color 1s ease-in-out,
    color 1s ease-in-out;
  -moz-transition: width 1s ease-in-out, background-color 1s ease-in-out,
    color 1s ease-in-out;
  -o-transition: width 1s ease-in-out, background-color 1s ease-in-out,
    color 1s ease-in-out;
  transition: width 1s ease-in-out, background-color 1s ease-in-out,
    color 1s ease-in-out;
  padding: 1rem;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.simple-widget .description-text a {
  color: transparent;
}

.simple-widget.active .description-text {
  width: 85%;
  background: #73262d;
  visibility: inherit;
  color: #f9f7f1;
}

.simple-widget .description-text a {
  color: #f9f7f1;
  font-weight: bold;
}

.simple-widget .description-text a img {
  filter: invert();
  width: 2rem;
}

.simple-widget .description-text a:hover {
  text-decoration: underline;
}
</style>
