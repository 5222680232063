<template>
  <div class="header-line"></div>
  <div
    class="container container-meta"
    :class="{ active: menu }"
    ref="categories"
  >
    <div class="row">
      <div class="col col-md-1"></div>
      <div class="meta-categories col-12 col-md-10" v-if="categories">
        <div v-for="(category, index) in categories" :key="index">
          <router-link
            :to="'/kategoria/' + category.slug"
            :title="category.name"
            @click="closeMenu()"
            >{{ category.name }}</router-link
          >
        </div>
      </div>
      <div
        v-if="!menu"
        class="col-12 col-sm-12 col-md-1 toggle-menu"
        @mouseover="ihover = true"
        @mouseleave="ihover = false"
      >
        <a @click="showMenu()" class="menu-click more-click" title="Pokaż menu"
          ><img src="@/assets/images/icoI.svg" v-if="!ihover" />
          <img src="@/assets/images/icoIah.svg" v-if="ihover" />
          <img
            src="@/assets/images/icoIkontrast.svg"
            v-if="!ihover"
            class="dark-icon"
          />
          <img
            src="@/assets/images/icokontrasthoov.svg"
            v-if="ihover"
            class="dark-icon"
          />
        </a>
      </div>
      <div v-if="menu" class="col-12 col-sm-12 col-md-1 toggle-menu">
        <a
          @click="showMenu()"
          class="menu-click more-click more-click-active"
          title="Ukryj menu"
          ><img src="@/assets/images/icoIah.svg" />
          <img
            src="@/assets/images/icoIkontrast.svg"
            v-if="!ihover"
            class="dark-icon"
          />
          <img
            src="@/assets/images/icokontrasthoov.svg"
            v-if="ihover"
            class="dark-icon"
          />
        </a>
      </div>
    </div>
    <div class="row pls-relative">
      <div class="menu" :class="{ active: menu }" v-if="menu">
        <div>
          <div class="menu-content">
            <span>{{ this.customConfig.ABOUT.ABOUT_US_HEADING }}</span>
            <p v-html="this.customConfig.ABOUT.ABOUT_US_CONTENT"></p>
          </div>
          <div class="menu-content">
            <span>{{ this.customConfig.ABOUT.ABOUT_PROJECT_HEADING }}</span>
            <p v-html="this.customConfig.ABOUT.ABOUT_PROJECT_CONTENT"></p>
            <span>{{ this.customConfig.ABOUT.ABOUT_CONTACT_HEADING }}</span>
            <p v-html="this.customConfig.ABOUT.ABOUT_CONTACT_CONTENT"></p>
          </div>
          <div class="search-module">
            <SearchModule />
          </div>
        </div>
      </div>
    </div>
    <div class="row meta-tags-mobile">
      <div class="col-12 col-sm-12">
        <label for="tag-select">Wybierz tag</label>
      </div>
      <div class="col-12 col-sm-12">
        <select
          name="tags"
          id="tag-select"
          v-model="stag"
          @change="selectTag()"
          ref="mobiletag"
        >
          <option
            v-for="(tag, index) in stags"
            :key="index"
            :value="tag.slug"
            class="opt"
            :tag-id="tag.id"
            :selected="tag.selected"
            :disabled="tag.disabled"
          >
            {{ tag.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row meta-tags-container">
      <div class="col col-md-11">
        <div class="meta-tags swiper" ref="swiper">
          <div class="meta-content swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(tag, index) in tags"
              :key="index"
              :tag-id="tag.id"
              ref="tag"
            >
              <router-link :to="'/tag/' + tag.slug" :title="tag.name"
                ><span v-html="tag.name"></span
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="meta-control col col-md-1"
        ref="control"
        @click="navigateSlider()"
      >
        <div>
          <img src="@/assets/images/arrow.png" class="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import httpClient from "@/scripts/httpClient";
import { Swiper, Mousewheel } from "swiper"; // eslint-disable-line
import "swiper/css";

import SearchModule from "@/components/SearchModule.vue";

Swiper.use([Mousewheel]);

export default {
  name: "FilterWidget",
  props: ["currentFilter"],
  components: {
    SearchModule
  },
  data: function () {
    return {
      data: null,
      tags: null,
      stags: null,
      stag: null,
      choosen_tags: null,
      categories: null,
      swiper: null,
      current: null,
      menu: false,
      ihover: false
    };
  },
  methods: {
    selectTag() {
      this.$router.push({ path: "/tag/" + this.stag });
    },
    closeMenu() {
      this.menu = false;
      document.querySelector("body").style.overflow = "auto";
    },
    showMenu() {
      this.menu = !this.menu;
      if (this.menu == true) {
        //this.$refs.categories.scrollIntoView({ behavior: "smooth" });
        document.querySelector("header").scrollIntoView({ behavior: "smooth" });
        document.querySelector("body").style.overflow = "hidden";
        let body = document.querySelector("body");
        body.removeAttribute("menu-active");
        body.setAttribute("menu-active", true);
      } else {
        document.querySelector("body").style.overflow = "auto";
        let body = document.querySelector("body");
        body.removeAttribute("menu-active");
        body.setAttribute("menu-active", false);
      }

      // for mobile

      // if(window.innerWidth <= 992) {
      //   document.querySelector("body").style.overflow = "auto";
      //   document.getElementsByClassName('menu').style.height = window.innerHeight + "px";
      // }
    },
    setUpCategories() {
      httpClient(this.customConfig.API_PATH + "/categories?per_page=100").then(
        (resp) => {
          this.data = resp.data;

          let _categories = [];

          this.data.forEach((c) => {
            _categories.push({
              id: c.id,
              count: c.count,
              name: c.name,
              slug: c.slug
            });
          });

          this.categories = _categories;
        }
      );
    },
    filterTags() {
      let _pages = [...Array(this.currentFilter.pages).keys()];
      let _data = [];

      _pages.forEach((p) => {
        httpClient(
          this.customConfig.API_PATH +
            "/posts?categories=" +
            this.currentFilter.id +
            "&page=" +
            (Number(p) + 1) +
            "&per_page=6"
        ).then((resp) => {
          _data = _data.concat(resp.data);

          let _choosen_tags = [];

          if (_data.length == this.currentFilter.total) {
            // get all ids of tags and hide tags outside array
            _data.forEach((post) => {
              _choosen_tags.push(post.tags);
            });
            this.choosen_tags = [...new Set(_choosen_tags.flat())];

            // hide some tags

            this.$refs.tag.forEach((tag) => {
              tag.classList.remove("hide-me");
              if (
                !this.choosen_tags.includes(Number(tag.getAttribute("tag-id")))
              ) {
                tag.classList.add("hide-me");
              }
            });

            [...this.$refs.mobiletag.children].forEach((tag) => {
              tag.classList.remove("hide-me");
              if (
                !this.choosen_tags.includes(Number(tag.getAttribute("tag-id")))
              ) {
                tag.classList.add("hide-me");
              }
            });
          }
        });
      });
    },
    setUpTags() {
      httpClient(this.customConfig.API_PATH + "/tags?per_page=100").then(
        (resp) => {
          this.data = resp.data;

          let _tags = [];

          this.data.forEach((t) => {
            if (t.count > 0) {
              _tags.push({
                id: t.id,
                count: t.count,
                name: t.name,
                slug: t.slug,
                selected: null,
                disabled: null
              });
            }

            if (_tags.length == this.data.length) {
              console.log(_tags);
            }
          });

          this.tags = _tags;
          // https://stackoverflow.com/a/6612410
          this.stags = _tags.slice(0);

          this.stags.unshift({
            id: null,
            count: null,
            name: "WYBIERZ TEMAT",
            slug: null,
            selected: true,
            disabled: true
          });

          this.setUpSlider();
        }
      );
    },
    setUpSlider() {
      this.swiper = new Swiper(this.$refs.swiper, {
        // Optional parameters
        direction: "horizontal",
        loop: false,
        slidesPerView: "auto",
        autoHeight: false,
        freemode: true,
        mousewheel: true,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });

      // controls

      let _this = this;

      this.swiper.on("toEdge", function () {
        _this.$refs.control.classList.toggle("reached-end");
      });

      this.swiper.on("init", function () {
        _this.navigateSlider();
      });
    },
    navigateSlider() {
      if (this.$refs.control.classList.contains("reached-end")) {
        this.swiper.slidePrev();
      } else {
        this.swiper.slideNext();
      }
    }
  },
  mounted: function () {
    this.setUpCategories();
    this.setUpTags();
  },
  watch: {
    currentFilter: {
      // kliknięcie w tag musi resetować wszystkie tagi!
      handler: function (val, oldVal) {
        if (
          (this.currentFilter.type == "category") &
          this.currentFilter.pages
        ) {
          this.filterTags();
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped></style>
