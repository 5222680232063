<template>
  <vue-progress-bar></vue-progress-bar>
  <CommonHeader />
  <div class="album" :class="[ready ? 'page-visible' : 'page-hidden']">
    <HeaderBanner />
    <FilterWidget :currentFilter="currentFilter" />
    <HomeBody :posts="posts" v-if="posts" />
    <pagination
      v-if="metadata && metadata.total > 5 && null"
      :options="paginationOptions"
      v-model="page"
      :records="metadata.total"
      :per-page="5"
      @paginate="goToPage(e)"
    />
    <div class="see-more" v-if="metadata && metadata.total > 5">
      <router-link to="/wpisy/" title="Zobacz wszystkie wpisy"
        ><span>Przeglądaj wszystko</span></router-link
      >
    </div>
  </div>
  <CommonFooter />
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import FilterWidget from "@/components/FilterWidget.vue";
import HeaderBanner from "@/components/HeaderBanner.vue";
import HomeBody from "@/bodies/HomeBody.vue";

import { useHead } from "@vueuse/head";
import httpClient from "@/scripts/httpClient";
import { removeHtml } from "@/scripts/removeHtml";
import Pagination from "v-pagination-3";
import { headTags } from "@/scripts/headTags";

export default {
  name: "HomeView",
  components: {
    CommonHeader,
    CommonFooter,
    FilterWidget,
    HeaderBanner,
    HomeBody,
    Pagination
  },
  data: function () {
    return {
      data: null,
      metadata: null,
      posts: null,
      title: this.customConfig.PAGE_TITLES.HOME,
      page: 1,
      currentFilter: null,
      ready: false,
    };
  },
  methods: {
    goToPage(e) {
      let self = this;
      this.$router.push({
        name: "list-all",
        params: {
          page: this.page
        }
      });
      this.$forceUpdate();
    },
    sendReq() {
      this.$Progress.start();
      httpClient(this.customConfig.API_PATH + "/posts?per_page=5")
        .then((resp) => {
          this.data = resp.data;
          this.metadata = {
            total: Number(resp.headers["x-wp-total"]),
            pages: Number(resp.headers["x-wp-totalpages"])
          };
          this.setUpPosts();
          //console.log(this.metadata);
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    setUpHead() {
      let head = {
        title: this.title,
        description: this.customConfig.PAGE_DESCS.HOME,
        type: "website",
        image:
          window.location.origin + require("@/assets/images/main-banner.png")
      };
      headTags(head);
    },
    setUpPosts() {
      let _posts = [];

      this.data.forEach((p, i) => {
        let postclass = "";

        if (p.sticky)
          postclass =
            "col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 no-border";

        let _o = i + 1;
        if (p.sticky) _o = 0;

        if (_o == 3) postclass = "";

        _posts.push({
          order: _o,
          id: p.id,
          title: p.title.rendered,
          category: { id: p.categories[0], slug: null, name: null, slug: null },
          excerpt: removeHtml(p.excerpt.rendered),
          image: { id: p.featured_media, src: null },
          slug: p.slug,
          sticky: p.sticky,
          class: postclass
        });
      });

      //_posts.sort((a,b) => b.sticky - a.sticky);

      let _this = this;

      this.posts = [];

      _posts.forEach((e, i) => {
        httpClient(this.customConfig.API_PATH + "/media/" + e.image.id)
          .then((resp) => {
            e.image.src = resp.data.media_details.sizes.thumbnail.source_url;
            if (e.sticky) e.image.src = resp.data.source_url;
            //_this.posts.push(e);
            //_this.posts.sort((a, b) => a.order - b.order);
            //_this.posts.sort((a, b) => b.sticky - a.sticky);

            // ask for categories

            httpClient(
              this.customConfig.API_PATH + "/categories/" + e.category.id
            )
              .then((resp) => {
                e.category.slug = resp.data.slug;
                e.category.name = resp.data.name;
                _this.posts.push(e);
                _this.posts.sort((a, b) => a.order - b.order);
                //_this.posts.sort((a, b) => b.sticky - a.sticky);
              })
              .catch((error) => {
                this.$router.push({
                  name: "error-page",
                  params: { error: error }
                });
              });
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error } });
          });
        if (i == _posts.length - 1) {
          _this.$Progress.finish();
          _this.ready = true;
        }
      });
    }
  },
  mounted: function () {
    this.setUpHead();
    this.sendReq();
  }
};
</script>
