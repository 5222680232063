/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SingleView from "../views/SingleView.vue";
import ListView from "../views/ListView.vue";
import ErrorView from "../views/ErrorView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/wpis/:post_slug",
    name: "single",
    component: SingleView,
  },
  {
    path: "/kategoria/:category_slug/:page?",
    name: "list-category",
    component: ListView
  },
  {
    path: "/tag/:tag_slug/:page?",
    name: "list-tag",
    component: ListView
  },
  {
    path: "/wpisy/:page?",
    name: "list-all",
    component: ListView
  },
  { 
    path: "/:pathMatch(.*)*", 
    name: "error-page",
    component: ErrorView, }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
