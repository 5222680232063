<template>
  <article>
    <div class="container py-4">
      <div class="row">
        <div class="article-header col-12">
          <h1 v-html="post.title"></h1>
        </div>
      </div>
      <div class="row">
        <div
          class="article-meta col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2"
        >
          <div class="article-date">
            <time :datetime="post.date">
              {{ post.date_string }}
            </time>
          </div>
          <div class="article-tags">
            <div v-for="(tag, index) in post.tags" :key="index">
              <router-link :to="'/tag/' + tag.slug" :title="tag.name">{{
                tag.name
              }}</router-link>
            </div>
          </div>
          <div class="article-share">
            <a
              :href="
                'http://www.facebook.com/sharer/sharer.php?u=' + this.share_url
              "
              ><img src="@/assets/images/facebookHC.svg"
            /></a>
            <a
              :href="
                'http://www.twitter.com/intent/tweet?url=' + this.share_url
              "
              ><img src="@/assets/images/twitterHC.svg"
            /></a>
          </div>
        </div>
        <div
          class="article-content col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8"
          v-html="post.content"
        ></div>
        <div class="col-12 col-lg-2 col-xl-2 col-xxl-2"></div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "SingleBody",
  props: ["post"],
  data: function () {
    return {
      share_url: null,
    };
  },
  mounted: function () {
    this.share_url = window.location.href;
  },
};
</script>
