<template>
  <vue-progress-bar></vue-progress-bar>
  <CommonHeader />
  <div class="album" :class="[ready ? 'page-visible' : 'page-hidden']">
    <HeaderBanner />
    <FilterWidget :key="key" :currentFilter="currentFilter" />
    <HomeBody :posts="posts" v-if="posts" :key="key" />
    <pagination
      v-if="metadata && metadata.total > 6"
      :options="paginationOptions"
      v-model="page"
      :records="metadata.total"
      :per-page="6"
      @paginate="goToPage(e)"
    />
  </div>
  <CommonFooter />
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import FilterWidget from "@/components/FilterWidget.vue";
import HeaderBanner from "@/components/HeaderBanner.vue";
import HomeBody from "@/bodies/HomeBody.vue";

import { useHead } from "@vueuse/head";
import httpClient from "@/scripts/httpClient";
import { removeHtml } from "@/scripts/removeHtml";
import Pagination from "v-pagination-3";
import { headTags } from "@/scripts/headTags";

export default {
  name: "ListView",
  components: {
    CommonHeader,
    CommonFooter,
    FilterWidget,
    HeaderBanner,
    HomeBody,
    Pagination
  },
  data: function () {
    return {
      data: null,
      metadata: null,
      posts: null,
      title: null,
      query: null,
      page: Number(this.$route.params.page),
      currentFilter: null,
      key: true,
      ready: false,
    };
  },
  methods: {
    goToPage(e) {
      let self = this;
      this.$router.push({
        name: this.$route.name,
        params: {
          page: this.page
        }
      });
      this.$forceUpdate();
    },
    setUpHead() {
      let head = {
        title: this.customConfig.PAGE_TITLES.LIST + this.title,
        description: this.customConfig.PAGE_DESCS.LIST,
        type: "website",
        image:
          window.location.origin + require("@/assets/images/main-banner.png")
      };
      headTags(head);
    },
    buildQuery() {
      if (Number(this.$route.params.page) == 0) {
        this.page = 1;
      }

      // pls build api uri
      if (this.$route.name == "list-all") {
        this.query = "/posts?per_page=6&page=" + this.page;
        this.currentFilter = {};
        this.title = "";
        this.sendReq();
      }
      if (this.$route.name == "list-category") {
        httpClient(
          this.customConfig.API_PATH +
            "/categories?slug=" +
            this.$route.params.category_slug
        )
          .then((resp) => {
            this.query =
              "/posts?per_page=6&page=" +
              this.page +
              "&categories=" +
              resp.data[0].id;
            // pls deprec
            this.currentFilter = {
              name: resp.data[0].name,
              slug: resp.data[0].slug,
              id: resp.data[0].id,
              total: null,
              pages: null,
              type: "category"
            };
            this.key = this.key;
            this.title = " : " + resp.data[0].name;
            this.sendReq();
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error } });
          });
        // tu formatuj listę tagów
        // ściągnij wszystko z danej kategorii i zrób listę tagów
        // zaznacz kategorię jako aktywną
      }
      if (this.$route.name == "list-tag") {
        httpClient(
          this.customConfig.API_PATH +
            "/tags?slug=" +
            this.$route.params.tag_slug
        )
          .then((resp) => {
            this.query =
              "/posts?per_page=6&page=" +
              this.page +
              "&tags=" +
              resp.data[0].id;
            this.currentFilter = {
              name: resp.data[0].name,
              slug: resp.data[0].slug,
              id: resp.data[0].id,
              total: null,
              pages: null,
              type: "tag"
            };
            this.key = this.key;
            this.title = " : " + resp.data[0].name;
            this.sendReq();
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error } });
          });
      }
    },
    sendReq() {
      this.setUpHead();
      this.$Progress.start();
      httpClient(this.customConfig.API_PATH + this.query)
        .then((resp) => {
          this.data = resp.data;
          this.metadata = {
            total: Number(resp.headers["x-wp-total"]),
            pages: Number(resp.headers["x-wp-totalpages"])
          };
          this.currentFilter.pages = Number(resp.headers["x-wp-totalpages"]);
          this.currentFilter.total = Number(resp.headers["x-wp-total"]);
          this.setUpPosts();
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    setUpPosts() {
      let _posts = [];

      this.data.forEach((p, i) => {
        let postclass = "";
        if (i + 1 == 4) postclass = "no-border";
        if (i + 1 == 1) postclass = "no-border";

        _posts.push({
          order: i + 1,
          id: p.id,
          title: p.title.rendered,
          category: { id: p.categories[0], slug: null, name: null, slug: null },
          excerpt: removeHtml(p.excerpt.rendered),
          image: { id: p.featured_media, src: null },
          slug: p.slug,
          sticky: false,
          class: postclass
        });
      });

      let _this = this;

      this.posts = [];

      _posts.forEach((e) => {
        httpClient(this.customConfig.API_PATH + "/media/" + e.image.id)
          .then((resp) => {
            e.image.src = resp.data.media_details.sizes.thumbnail.source_url;
            if (e.sticky) e.image.src = resp.data.source_url;
            //_this.posts.push(e);
            //_this.posts.sort((a, b) => a.order - b.order);

            httpClient(
              this.customConfig.API_PATH + "/categories/" + e.category.id
            ).then((resp) => {
              e.category.slug = resp.data.slug;
              e.category.name = resp.data.name;
              _this.posts.push(e);
              _this.posts.sort((a, b) => a.order - b.order);
              _this.posts.sort((a, b) => b.sticky - a.sticky);
            });
          })
          .then((a) => {
            this.$Progress.finish();
            this.ready = true;
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error } });
          });
      });
    }
  },
  mounted: function () {
    this.buildQuery();
  },
  watch: {
    $route(to, from) {
      this.buildQuery();
      this.key = this.key;
      if (to.name != from.name) {
        this.page = 1;
        this.data = null;
        this.metadata = null;
        this.posts = null;
      }
    }
  }
};
</script>
