<template>
  <div class="privacy-footer" :class="{ active: isActive }">
    <div class="privacy-content">
      <a
        target="_blank"
        title="Polityka prywatności UW"
        href="https://www.uw.edu.pl/polityka-obslugi-cookies-ciasteczek-w-serwisach-uw/"
        >Na stronach internetowych Uniwersytetu Warszawskiego stosowane są pliki
        „cookies” zgodnie z polityką prywatności.</a
      >
    </div>
    <div class="privacy-button">
      <span @click="setPrivacy()">Zgadzam się</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyFooter",
  data() {
    return {
      isActive: false,
    };
  },
  mounted: function () {
    this.checkLs();
  },
  methods: {
    setPrivacy() {
      this.isActive = false;
      localStorage.setItem("hc-privacy-off", JSON.stringify(true));
    },
    checkLs() {
      let l = JSON.parse(localStorage.getItem("hc-privacy-off"));
      console.log(l);
      if (!l) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>
