<template>
  <vue-progress-bar></vue-progress-bar>
  <CommonHeader />
  <div class="album" :class="[ready ? 'page-visible' : 'page-hidden']">
    <BreadCrumbs :post="post" v-if="post" />
    <SingleBody :post="post" v-if="post" />
  </div>
  <CommonFooter />
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import SingleBody from "@/bodies/SingleBody.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

import { useHead } from "@vueuse/head";
import httpClient from "@/scripts/httpClient";
import { removeHtml } from "@/scripts/removeHtml";
import { headTags } from "@/scripts/headTags";

export default {
  name: "SingleView",
  components: {
    CommonHeader,
    CommonFooter,
    SingleBody,
    BreadCrumbs
  },
  data: function () {
    return {
      data: null,
      post: null,
      title: this.customConfig.PAGE_TITLES.HOME,
      ready: false,
    };
  },
  methods: {
    sendReq() {
      this.$Progress.start();
      httpClient(
        this.customConfig.API_PATH +
          "/posts?slug=" +
          this.$route.params.post_slug
      )
        .then((resp) => {
          this.data = resp.data;
          this.setUpPost();
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    setUpHead() {
      let head = {
        title: this.customConfig.PAGE_TITLES.LIST + " : " + this.post.title,
        description: this.post.excerpt,
        type: "article",
        image: this.post.image.src
      };
      headTags(head);
    },
    setUpPost() {
      let p = this.data[0];

      let _post = {
        id: p.id,
        title: p.title.rendered,
        excerpt: removeHtml(p.excerpt.rendered),
        content: p.content.rendered,
        image: { id: p.featured_media, src: null },
        slug: p.slug,
        date: p.date,
        date_string: null,
        sticky: p.sticky,
        class: null,
        category: {
          id: null,
          name: null,
          slug: null
        },
        tags: null,
        author: null
      };

      let _d = new Date(_post.date);
      _post.date_string = _d.toLocaleString('pl', { day: "numeric", month: "long", year: "numeric" });


      if (p.categories.length > 0) {
        httpClient(
          this.customConfig.API_PATH + "/categories/" + p.categories[0]
        )
          .then((resp) => {
            _post.category.id = p.categories[0];
            _post.category.name = resp.data.name;
            _post.category.slug = resp.data.slug;
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error }});
          });
      }

      httpClient(this.customConfig.API_PATH + "/users/" + p.author)
        .then((resp) => {
          _post.author = {
            id: p.author,
            name: resp.data.name
          };
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });

      if (p.tags.length > 0) {
        _post.tags = [];
        p.tags.forEach((tag) => {
          httpClient(this.customConfig.API_PATH + "/tags/" + tag)
            .then((resp) => {
              _post.tags.push({
                id: tag,
                name: resp.data.name,
                slug: resp.data.slug
              });
            })
            .catch((error) => {
              this.$router.push({
                name: "error-page",
                params: { error: error }
              });
            });
        });
      }

      httpClient(this.customConfig.API_PATH + "/media/" + _post.image.id)
        .then((resp) => {
          _post.image.src = resp.data.source_url;

          let _this = this;

          setTimeout(function () {
            _this.post = _post;
            _this.setUpHead();
            _this.$Progress.finish();
            _this.ready = true;
          }, 100);
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    }
  },
  created: function () {},
  mounted: function () {
    this.sendReq();
  },
  watch: {
    post: {
      // kliknięcie w tag musi resetować wszystkie tagi!
      handler: function (val, oldVal) {},
      deep: true
    }
  }
};
</script>