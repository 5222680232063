<template>
  <div class="container container-news">
    <div
      class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 g-3 align-items-start border-between"
    >
      <div
        :class="post.class"
        class="col"
        v-for="(post, index) in posts"
        :key="index"
        :order="post.order"
      >
        <div class="card">
          <div class="overlay">
            <router-link
              :to="{ path: '/wpis/' + post.slug, slug: post.slug, id: post.id }"
              :title="post.title"
            >
              <img :src="post.image.src" />
            </router-link>
          </div>
          <div class="card-body">
            <router-link
              :to="{ path: '/wpis/' + post.slug, slug: post.slug, id: post.id }"
              :title="post.title"
              ><h2 class="card-title"><span v-html="post.title"></span></h2
            ></router-link>
            <div class="card-category" v-if="post.category.name">
              <router-link
                :title="post.category.name"
                :to="'/kategoria/' + post.category.slug"
                >{{ post.category.name }}</router-link
              >
            </div>
            <p class="card-text" v-html="post.excerpt"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBody",
  props: ["posts"],
};
</script>
