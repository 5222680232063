<template>
  <div class="container container-banner">
    <div class="row">
      <div>
        <router-link to="/"
          ><img src="@/assets/images/ban_1160v3bl_niski.svg" />
          <img src="@/assets/images/ban_1160v3_niskikontr.svg" />
          <img
            src="@/assets/images/ban_1160v3bl_okrojony.svg"
            class="mobile-banner"
          />
          <img
            src="@/assets/images/ban_1160v3_okrojonykontr.svg"
            class="mobile-banner"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBanner",
};
</script>
