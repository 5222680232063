/* eslint-disable */

export function headTags(m) {
    
    document.title = m.title;

    document.querySelector("meta[name='description']").remove()
    let meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = m.description;
    document.head.appendChild(meta);
    
    document.querySelector("meta[property='og:type']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("property", "og:type");
    meta.content = m.type;
    document.head.appendChild(meta);

    document.querySelector("meta[property='og:title']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("property", "og:title");
    meta.content = m.title;
    document.head.appendChild(meta);

    document.querySelector("meta[name='twitter:title']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("name", "twitter:title");
    meta.content = m.title;
    document.head.appendChild(meta);

    document.querySelector("meta[name='twitter:text:title']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("name", "twitter:text:title");
    meta.content = m.title;
    document.head.appendChild(meta);

    document.querySelector("meta[property='og:url']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("property", "og:url");
    meta.content = window.location.href;
    document.head.appendChild(meta);

    document.querySelector("meta[property='og:image']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("property", "og:image");
    meta.content = m.image;
    document.head.appendChild(meta);

    document.querySelector("meta[name='twitter:image']").remove()
    meta = document.createElement('meta');
    meta.setAttribute("name", "twitter:image");
    meta.content = m.image;
    document.head.appendChild(meta);

    return;


}