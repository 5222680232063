import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
//import VueMeta from "vue-meta";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import mitt from "mitt";
import VueGtag from "vue-gtag";

// CSS styles

require("bootstrap/dist/css/bootstrap.min.css");
require("./assets/styles/theme.css");

// BAR

const barOptions = {
  color: "#73262D",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

const paginationOptions = {
  format: false,
  edgeNavigation: true,
  hideCount: true,
  chunk: 5,
  chunksNavigation: "scroll",
  texts: {
    first: "<<",
    last: ">>",
  },
};

/* const metaOptions = {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
}; */

// SETTINGS

fetch("/config.json")
  .then((res) => res.json())
  .then((config) => {
    const app = createApp(App);

    const head = createHead();

    const emitter = mitt();

    app.config.globalProperties.paginationOptions = paginationOptions;
    app.config.globalProperties.$emitter = emitter;
    app.config.globalProperties.customConfig = config;

    app
      .use(router)
      .use(head)
      .use(VueGtag, { config: { id: "G-K97YD6DENZ" } })
      .use(emitter)
      //.use(VueMeta, metaOptions)
      .use(VueProgressBar, barOptions)
      .mount("#app");
  });
