<template>
  <div class="container container-breadcrumbs">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Strona główna</router-link>
        </li>
        <li class="breadcrumb-item" v-if="post.category.id">
          <router-link
            :to="'/kategoria/' + post.category.slug"
            v-html="post.category.name"
          ></router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          v-html="post.title"
        ></li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  props: ["post"],
};
</script>
