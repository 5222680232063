<template>
  <simple-widget />
  <footer class="text-muted container">
    <div class="row">
      <div
        class="col-12 col-sm-12 col-md-7 footer-adress"
        v-html="this.customConfig.FOOTER.FOOTER_COL_1"
      ></div>
      <div class="col-12 col-sm-12 col-md-5 footer-logos">
        <a
          href="https://uw.edu.pl/"
          target="_blank"
          title="Uniwersytet Warszawski"
          ><img src="@/assets/images/uw.png" class="uw-logo" />
          <img src="@/assets/images/uw_contrast.png" class="uw-logo" />
        </a>
        <a
          href="https://ckc.uw.edu.pl/"
          target="_blank"
          title="Centrum Kompetencji Cyfrowych UW"
          ><img src="@/assets/images/ckc.svg" class="ckc-logo"
        /></a>
        <a
          title="Inicjatywa doskonałości – uczelnia badawcza"
          href="https://inicjatywadoskonalosci.uw.edu.pl/"
          target="_blank"
          ><img src="@/assets/images/idub.svg" class="idub-logo"
        /></a>
      </div>
      <div
        class="col col-md-12 footer-about"
        v-html="this.customConfig.FOOTER.FOOTER_COL_2"
      ></div>
    </div>
  </footer>
  <privacy-footer />
</template>

<script>
import PrivacyFooter from "@/components/PrivacyWidget.vue";
import SimpleWidget from "@/components/SimpleWidget.vue";
export default {
  name: "CommonFooter",
  components: {
    PrivacyFooter,
    SimpleWidget,
  },
};
</script>
